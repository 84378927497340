/// Generated by expo-google-fonts/generator
/// Do not edit by hand unless you know what you are doing
///

export { useFonts } from './useFonts';

export { default as __metadata__ } from './metadata.json';
export const NotoSansArabic_100Thin = require('./NotoSansArabic_100Thin.ttf');
export const NotoSansArabic_200ExtraLight = require('./NotoSansArabic_200ExtraLight.ttf');
export const NotoSansArabic_300Light = require('./NotoSansArabic_300Light.ttf');
export const NotoSansArabic_400Regular = require('./NotoSansArabic_400Regular.ttf');
export const NotoSansArabic_500Medium = require('./NotoSansArabic_500Medium.ttf');
export const NotoSansArabic_600SemiBold = require('./NotoSansArabic_600SemiBold.ttf');
export const NotoSansArabic_700Bold = require('./NotoSansArabic_700Bold.ttf');
export const NotoSansArabic_800ExtraBold = require('./NotoSansArabic_800ExtraBold.ttf');
export const NotoSansArabic_900Black = require('./NotoSansArabic_900Black.ttf');
