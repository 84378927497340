/// Generated by expo-google-fonts/generator
/// Do not edit by hand unless you know what you are doing
///

export { useFonts } from './useFonts';

export { default as __metadata__ } from './metadata.json';
export const SignikaNegative_300Light = require('./SignikaNegative_300Light.ttf');
export const SignikaNegative_400Regular = require('./SignikaNegative_400Regular.ttf');
export const SignikaNegative_500Medium = require('./SignikaNegative_500Medium.ttf');
export const SignikaNegative_600SemiBold = require('./SignikaNegative_600SemiBold.ttf');
export const SignikaNegative_700Bold = require('./SignikaNegative_700Bold.ttf');
